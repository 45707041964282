<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-media class="d-flex flex-column" no-body>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="
                profile_image && profile_image !== null
                  ? profile_image
                  : 'https://aircraft.wpenginepowered.com/wp-content/uploads/2016/09/no-profile-img.jpg'
              "
              height="80"
            />
          </b-link>
        </b-media-aside>
        <b-media-body class="mt-75 ml-75">
          <b-button
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="handleClickProfileImage"
          >
            Upload
            <div style="display: none">
              <b-form-file
                v-model="profile_image"
                id="fileUpload"
                accept="image/*"
                @input="handleFileChange($event, 'Customer', 'profile_image')"
              />
            </div>
          </b-button>
          <b-button
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="removeprofileImage"
          >
            Reset
          </b-button>
        </b-media-body>
      </b-media>
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Lead Source</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Lead Source"
              >
                <v-select
                  v-model="selectedLeadSource"
                  label="name"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="leadSourceOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Name">
                <b-form-input
                  v-model="name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Surname</label>
              <b-form-input v-model="surname" placeholder="Enter Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Client's Father/Husband Name</label>
              <b-form-input v-model="fathername" placeholder="Enter Name" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Mobile">
                <b-form-input
                  v-model="mobile"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Gender</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Gender">
                <v-select
                  v-model="gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  placeholder="None"
                  :options="['Male', 'Female']"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" class="mb-1 mt-1">
            <span style="font-weight: 500; font-size: 1.285rem">Current Address</span>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 1</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address Line 1"
              >
                <b-form-input
                  v-model="curaddressline1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address Line 2</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Address Line 2"
              >
                <b-form-input
                  v-model="curaddressline2"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Address Line 2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:6"
                name="Pin Code"
              >
                <b-form-input
                  v-model="curpincode"
                  maxlength="6"
                  @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Pin Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>City</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="City">
                <v-select
                  v-model="curcity"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="City"
                  :options="curcityOption"
                  @input="clickArea($event)"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Area">
                <v-select
                  v-model="curarea"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="PostOfficeName"
                  :options="curareaOption"
                  placeholder="None"
                  @input="handleCurPincode()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Taluka</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Taluka">
                <b-form-input
                  v-model="curtaluka"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Enter Taluka"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="State">
                <v-select
                  v-model="curstate"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="stateOptions"
                  placeholder="None"
                  label="State"
                  @input="handleCurCity()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar No</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Aadhar No"
              >
                <b-form-input
                  v-model="aadharcardno"
                  maxlength="14"
                  @input="validateAadharClick($event)"
                  placeholder="Enter Aadhar No"
                />
                <small v-if="aadharcardnoValidate == false" class="text-danger"
                  >Enter Valid Aadhar No</small
                >
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Front Side</label>
              <b-form-file
                v-model="aadharfrontside"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'customer', 'aadharfrontside')"
              />
            </b-form-group>
            <attachment :data="aadharfrontside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Aadhar Card Back Side</label>
              <b-form-file
                v-model="aadharbackside"
                placeholder="Select Photo"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'customer', 'aadharbackside')"
              />
            </b-form-group>
            <attachment :data="aadharbackside" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remark</label>
              <b-form-textarea v-model="remark" placeholder="Enter Remark" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
              :disabled="submitDisable"
            >
              Submit
            </b-button>
            <b-button variant="primary" v-if="fromSales !== true" @click="onClickBack"
              >Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BFormCheckbox,
  BMedia,
  BMediaAside,
  BFormFile,
  BMediaBody,
  BLink,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    BAvatar,
    BFormCheckbox,
    BFormFile,
    Attachment,
    flatPickr,
    Datepicker,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
  },
  props: ["fromSales", "selectedUser", "closePopUp", "getCustomer"],
  data() {
    return {
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      imagePath: process.env.VUE_APP_IMAGE_PATH,

      selectedLeadSource: "",
      selectedSubSource: "",
      leadSourceOption: [],
      subSourceOption: [],
      leadValue: "",
      name: "",
      fathername: "",
      surname: "",
      test: "",
      leadDate: null,
      // nextfollowupdate: moment(new Date()).format("DD/MM/yyyy"),
      mobile: "",
      email: "",
      curaddressline1: "",
      curaddressline2: "",
      curarea: "",
      curtaluka: "",
      curstate: "",
      curcity: "",
      curpincode: "",
      peraddressline1: "",
      peraddressline2: "",
      perarea: "",
      pertaluka: "",
      perstate: "",
      percity: "",
      perpincode: "",
      curcityOption: [],
      curareaOption: [],
      percityOption: [],
      perareaOption: [],
      remark: "",
      selectedAssignTo: "",
      stateOptions: [],
      assignToOption: [],
      fromState: "",
      ifEdit: false,
      selectedType: "",
      role: "",
      userId: "",
      fromCurState: "",
      fromState: "",

      birthdate: null,
      profile_image: "",
      aadharfrontside: "",
      aadharbackside: "",
      pancard: "",
      voterIdPhoto: "",
      aadharcardno: "",
      panno: "",
      occupation: "",
      voterIdNo: "",

      pannoValidate: true,
      aadharcardnoValidate: true,
      submitDisable: false,
      test: "",
      testpan: "",
      testaadhar: "",
      gender: "",
      maritalstatus: "",
      anniversarydate: null,
      nomineename: "",
      nomineerelation: "",
      nomineephone: "",
      nomineedob: moment(new Date()).format("DD/MM/yyyy"),
      title: "",

      allCp: "",
      cp: "",
      allFr: "",
      fr: "",
      userData: "",
      display: true,
      type: "",
    };
  },
  mounted() {
    const rights = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin" && !this.fromSales && !this.selectedUser) {
      rights.map((item) => {
        if (item.modulename == "Customer") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/customer");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/customer");
          }
        }
      });
    }
    this.userData = this.$store.state.app.user_data;

    this.role = this.userData.role;
    this.type = this.userData.type;
    this.userId = this.userData.id;

    this.init();
    this.selectedAssignTo = this.selectedUser;
  },
  methods: {
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    async handleFileChange(e, type, name) {
      this.submitDisable = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          const vue = this;
          this.submitDisable = false;

          vue[name] = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => {
          this.submitDisable = false;
        });
    },
    removeprofileImage() {
      this.profileImage = null;
    },
    handleClickProfileImage() {
      document.getElementById("fileUpload").click();
    },
    handleCurPincode() {
      if (this.fromCurState == true) {
        this.curpincode = this.curarea.Pincode;
      }
    },
    async handleCurCity() {
      this.curareaOption = [];
      this.curcityOption = [];
      this.fromCurState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.curstate,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curcityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleCurArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.curcity,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.curareaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromCurState == true) {
        this.handleCurArea();
      }
    },
    async clickPincode() {
      this.curcity = "";
      this.curarea = "";
      this.curstate = "";
      this.curareaOption = [];
      this.curcityOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.curpincode)) {
        this.fromCurState = false;

        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.curpincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.curcityOption = [];
            this.curStateData = response.data.data;
            this.curareaOption = response.data.data;
            this.curstate = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.curcityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    location() {
      this.handleCity();
      this.handleCurCity();
      this.handleCurArea();
      this.handleArea();
    },
    reset() {},
    submitForm(e) {
      const data = {
        branch: 1,
        company: 1,
        active: 1,
        leadsource: this.selectedLeadSource.id,
        name: this.name,
        fathername: this.fathername,
        surname: this.surname,
        mobile: this.mobile,
        curaddressline1: this.curaddressline1,
        curaddressline2: this.curaddressline2,
        curarea: this.curarea.PostOfficeName,
        curtaluka: this.curtaluka,
        curcity: this.curcity.City,
        curpincode: this.curpincode,
        curstate: this.curstate.State,
        remarks: this.remark,
        assignto: this.selectedAssignTo.id,
        aadharfrontside: this.aadharfrontside,
        aadharbackside: this.aadharbackside,
        profile_image: this.profile_image,
        aadharcardno: this.aadharcardno,
        gender: this.gender,
      };
      // var ifEdit = this.$route.params.id ? true : false;
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/customer/${this.$route.params.id}`
              : `${baseApi}/customer`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              if (this.fromSales == true) {
                this.getCustomer(this.selectedUser.id);
                this.closePopUp();
              } else {
                this.$router.push("/crm/customer");
              }
            })
            .catch((error) => {
              this.submitDisable = false;

              let email, name, message;
              const code = error.toString().includes("409");
              if (code) {
                email = error.response.data.message.original.email
                  ? error.response.data.message.original.email[0]
                  : "";
                name = error.response.data.message.original.name
                  ? error.response.data.message.original.name[0]
                  : "";
                message = email + "  " + name;
              }
              this.$swal({
                title: "Error!",
                text: code ? `${message}` : error,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    async init() {
      this.getLeadSource();
      // this.getAssignSource();
      this.getState();
    },
    async getLeadSource(id, subid) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/leadsourcedata`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.leadSourceOption = response.data.data.filter((item) => {
            if (
              item.name == "Franchise" &&
              this.userData.role != "admin" &&
              this.userData.role != "frachise"
            ) {
              return false;
            } else if (
              (item.name == "Channel Partner" || item.name == "Employee") &&
              this.userData.role == "frachise"
            ) {
              return false;
            } else if (
              (this.userData.role == "cannelpartner" ||
                this.userData.role == "employee") &&
              item.name == "Frachise"
            ) {
              return false;
            } else {
              return true;
            }
          });
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>
