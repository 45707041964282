<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col
            md="4"
            :style="type == 'admin' || type == 'sitemanager' ? '' : 'display:none'"
          >
            <b-form-group>
              <label>Source</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="type == 'sitemanager' ? '' : 'required'"
                name="Source"
              >
                <v-select
                  v-model="sourcetype"
                  :disabled="
                    selectedUserFollowup || (role != 'admin' && type != 'sitemanager')
                      ? true
                      : false
                  "
                  placeholder="None"
                  :options="['Company', 'Channel Partner', 'Franchise', 'Employee']"
                  @input="handleSource($event)"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" :style="sourcetype !== 'Company' ? '' : 'display:none'">
            <b-form-group>
              <label>Select Source</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="
                  sourcetype == 'Company' || type == 'sitemanager' ? '' : 'required'
                "
                name="Select Source"
              >
                <!-- <v-select
                  v-model="source"
                  placeholder="None"
                  :options="sourceOptions"
                  :disabled="
                    selectedUserFollowup ||
                    type == 'siteassistant' ||
                    type == 'driver' ||
                    farmmanager == true
                      ? true
                      : false
                  "
                  label="name"
                  @input="handleClient($event)"
                /> -->
                <v-select
                  v-model="source"
                  placeholder="None"
                  :options="sourceOptions"
                  :disabled="
                    selectedUserFollowup ||
                    type == 'siteassistant' ||
                    type == 'driver' ||
                    farmmanager == true
                      ? true
                      : false
                  "
                  label="name"
                  @input="handleClient($event)"
                >
                  <template #option="{ name, profile_image, username, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                    ><span>({{ username }})</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="type == 'admin' || type == 'sitemanager'">
            <b-form-group>
              <label>Site Visit Manager</label>
              <label
                v-if="type == 'admin' || type == 'sitemanager'"
                style="color: red !important"
                >*</label
              >

              <validation-provider #default="{ errors }" name="Select Manager">
                <v-select
                  v-model="manager"
                  placeholder="None"
                  :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
                  @input="handleCount"
                  :options="assigntoOptions"
                  label="fullname"
                >
                  <template #option="{ name, profile_image, username, surname }">
                    <b-avatar :src="getprofileImage(profile_image)" />
                    <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                    ><span>({{ username }})</span>
                  </template>
                </v-select>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="
              type == 'admin' || type == 'sitemanager' || type == 'siteassistant'
                ? ''
                : 'display:none'
            "
          >
            <b-form-group>
              <label>Select Assistant</label>
              <v-select
                v-model="assistant"
                :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
                placeholder="None"
                :options="assistantOptions"
                label="name"
              >
                <template #option="{ name, profile_image, username, surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                  ><span>({{ username }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            v-if="
              fromFollowup == true ||
              (type !== 'sitemanager' &&
                type !== 'admin' &&
                type !== 'channelpartner' &&
                source &&
                source.id !== userID)
            "
          >
            <b-form-group>
              <label>Client Name</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" name="Client name">
                <v-select
                  v-model="clientname"
                  placeholder="None"
                  :options="clientOptions"
                  :disabled="true"
                  label="name"
                  @input="handleClientPerson($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-else>
            <b-row>
              <b-col>
                <b-form-group>
                  <label>Client Name</label>
                  <label style="color: red !important">*</label>

                  <validation-provider
                    #default="{ errors }"
                    :rules="
                      (source && source.id == userID) ||
                      type == 'channelpartner' ||
                      role == 'admin' ||
                      type == 'sitemanager'
                        ? 'required'
                        : ''
                    "
                    name="Client Name"
                  >
                    <v-select
                      v-model="clientname"
                      placeholder="None"
                      :options="clientOptions"
                      :disabled="
                        (source && source.id == userID) ||
                        type == 'channelpartner' ||
                        type == 'admin' ||
                        type == 'sitemanager'
                          ? false
                          : true
                      "
                      label="name"
                      @input="handleClientPerson($event)"
                    >
                      <template #option="{ name, profile_image, username, surname }">
                        <b-avatar :src="getprofileImage(profile_image)" />
                        <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                        ><span>({{ username }})</span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <div class="d-flex align-items-center" style="height: 82px">
                <b-button variant="primary" size="sm" @click="handlePopUp"> + </b-button>
              </div>
            </b-row>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>No. Of Person</label>
              <b-form-input
                v-model="noofperson"
                placeholder="Enter No. Of Person"
                type="number"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                @input="handlePerson($event)"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4" :style="type == 'channelpartner' ? 'display:none' : ''">
            <b-form-group>
              <label>Driver Type</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="role == 'channelpartner' ? '' : 'required'"
                name="Driver Type"
              >
                <v-select
                  v-model="drivertype"
                  placeholder="None"
                  :disabled="type == 'channelpartner' ? true : false"
                  :options="['Self', 'Company', 'Channel Partner']"
                  @input="handleDriver($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="4">
            <b-form-group>
              <label> Arrival Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Arrival date"
              >
                <flat-pickr
                  v-model="arrivaldate"
                  class="form-control"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                  }"
                  :style="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? 'background-color: transparent'
                      : 'background-color: #f8f8f8; cursor: not-allowed'
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Arrival Time</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Arrival time"
              >
                <flat-pickr
                  v-model="arrivaltime"
                  class="form-control"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  :config="{
                    dateFormat: 'h:i K',
                    time_24hr: false,
                    noCalendar: true,
                    enableTime: true,
                    defaultDate: new Date(),
                  }"
                  :style="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? 'background-color: transparent'
                      : 'background-color: #f8f8f8; cursor: not-allowed'
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Travel By</label>
              <v-select
                v-model="travelby"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                placeholder="None"
                :options="['Flight', 'Train', 'Bus', 'Other']"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Pickup Point</label>

              <b-form-input
                v-model="pickuppoint"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                placeholder="Enter Pickup point"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Vehicle Required ? </label>
              <v-select
                v-model="vehiclereq"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                placeholder="None"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="
              (type == 'admin' ||
                type == 'sitemanager' ||
                (source && source.id == userID) ||
                type == 'channelpartner') &&
              vehiclereq == 'Yes'
                ? ''
                : 'display:none'
            "
          >
            <b-form-group>
              <label>Vehicle No.</label>
              <v-select
                v-model="vehicleno"
                placeholder="None"
                :options="vehicleOptions"
                :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
                label="vehicleno"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="
              vehicleno !== 0 && vehiclereq == 'Yes' && vehicleno.vehicleno == 'Other'
                ? ''
                : 'display:none'
            "
          >
            <b-form-group>
              <label>Remarks</label>
              <b-form-input
                v-model="remarks.vehicleRemarks"
                placeholder="Enter Remarks"
                :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Select Driver</label>
              <v-select
                v-model="driver"
                placeholder="None"
                :options="driverOptions"
                :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
                label="name"
              >
                <template #option="{ name, profile_image, username, surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                  ><span>({{ username }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="
              driver && vehiclereq == 'Yes' && driver.name == 'Other'
                ? ''
                : 'display:none'
            "
          >
            <b-form-group>
              <label>Remarks</label>
              <b-form-input
                v-model="remarks.driverRemarks"
                placeholder="Enter Remarks"
                :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
              />
            </b-form-group>
          </b-col>

          <!-- <b-col
            md="4"
            :style="type == 'channelpartner' ? 'display:none' : 'display:none'"
            v-else
          >
            <b-form-group>
              <label>Vehicle No.</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="role == 'channelpartner' ? '' : 'required'"
                name="Vehicle No"
              >
                <b-form-input
                  v-model="vehicleno"
                  :disabled="type == 'channelpartner' ? true : false"
                  placeholder="Enter Vehicle No."
                  @input="validateVehicalClick()"
                />

                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="vehicleValidate == false" class="text-danger"
                  >Enter Valid Vehical No</small
                >
              </validation-provider>
            </b-form-group>
          </b-col> -->

          <b-col md="4">
            <b-form-group>
              <label>Site Visit Date</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Site Visit"
              >
                <flat-pickr
                  v-model="visitdate"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                  }"
                  :style="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? 'background-color: transparent'
                      : 'background-color: #f8f8f8; cursor: not-allowed'
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Site Visit Time</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Arrival Time"
              >
                <flat-pickr
                  v-model="visittime"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  class="form-control"
                  :config="{
                    dateFormat: 'h:i K',
                    time_24hr: false,

                    noCalendar: true,
                    enableTime: true,
                  }"
                  :style="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? 'background-color: transparent'
                      : 'background-color: #f8f8f8; cursor: not-allowed'
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Stay</label>
              <label style="color: red !important">*</label>

              <validation-provider #default="{ errors }" rules="required" name="Stay">
                <v-select
                  v-model="isstay"
                  placeholder="None"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  label="name"
                  :options="[
                    { id: 1, name: 'Yes' },
                    { id: 0, name: 'No' },
                  ]"
                  @input="handleIsStay"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label> Departure Date</label>
              <flat-pickr
                v-model="departurdate"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                :style="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? 'background-color: transparent'
                    : 'background-color: #f8f8f8; cursor: not-allowed'
                "
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Drop Time</label>
              <flat-pickr
                v-model="droptime"
                class="form-control"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                :config="{
                  dateFormat: 'h:i K',
                  time_24hr: false,
                  noCalendar: true,
                  enableTime: true,
                  defaultDate: new Date(),
                }"
                :style="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? 'background-color: transparent'
                    : 'background-color: #f8f8f8; cursor: not-allowed'
                "
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Drop Location</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="type == 'sitemanager' ? '' : 'required'"
                name="Drop Location"
              >
                <b-form-input
                  v-model="droplocation"
                  placeholder="Enter Location"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="isstay.id == 1">
            <b-form-group>
              <label> Accommodation Start Date</label>
              <flat-pickr
                v-model="accommodationstart"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }"
                :style="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? 'background-color: transparent'
                    : 'background-color: #f8f8f8; cursor: not-allowed'
                "
              />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="isstay.id == 1">
            <b-form-group>
              <label> Accommodation End Date</label>
              <flat-pickr
                v-model="accommodationend"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                }"
                :style="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? 'background-color: transparent'
                    : 'background-color: #f8f8f8; cursor: not-allowed'
                "
              />
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            v-if="
              isstay.id == 1 &&
              (type == 'admin' ||
                type == 'sitemanager' ||
                (source && source.id == userID) ||
                type == 'channelpartner')
            "
          >
            <b-form-group>
              <label>Stay Location</label>
              <label style="color: red !important">*</label>

              <validation-provider
                #default="{ errors }"
                :rules="type == 'admin' || type == 'sitemanager' ? 'required' : ''"
                name="Stay Location"
              >
                <v-select
                  v-model="stay"
                  :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
                  placeholder="None"
                  :options="['Hotel', 'Banglow', 'Greentech', 'Farm House', 'Other']"
                  @input="handleFarmManager"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" :style="stay == 'Hotel' ? '' : 'display:none'">
            <b-form-group>
              <label>Hotel Name</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                :rules="stay == 'Hotel' ? 'required' : ''"
                :disabled="
                  type == 'admin' ||
                  type == 'sitemanager' ||
                  (source && source.id == userID) ||
                  type == 'channelpartner'
                    ? false
                    : true
                "
                name="Hotel Name"
              >
                <b-form-input v-model="hotelname" placeholder="Enter Hotel Name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="stay && stay != 'Hotel' && stay != 'Other' ? '' : 'display:none'"
          >
            <b-form-group>
              <label>Select {{ stay == "Greenteck" ? "Guest" : stay }} Manager</label>
              <v-select
                v-model="guestmanager"
                placeholder="None"
                :options="guestmanagerOption"
                :disabled="type == 'admin' || type == 'sitemanager' ? false : true"
                label="name"
              >
                <template #option="{ name, profile_image, username, surname }">
                  <b-avatar :src="getprofileImage(profile_image)" />
                  <span class="font-weight-bolder"> {{ name }} {{ surname }}</span
                  ><span>({{ username }})</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Site Visit Projects</label>
              <b-form-input
                v-model="project"
                :disabled="
                  (source && source.id == userID) ||
                  type == 'channelpartner' ||
                  type == 'admin' ||
                  type == 'sitemanager'
                    ? false
                    : true
                "
                placeholder="Enter Project"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks.remarks" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label="Channal Partner Visit With Us">
              <b-form-radio-group
                id="radio-group-2"
                v-model="withcp"
                name="radio-sub-component-1"
              >
                <b-form-radio
                  name="some-radios"
                  value="Yes"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  >Yes</b-form-radio
                >
                <b-form-radio
                  name="some-radios"
                  value="No"
                  :disabled="
                    (source && source.id == userID) ||
                    type == 'channelpartner' ||
                    type == 'admin' ||
                    type == 'sitemanager'
                      ? false
                      : true
                  "
                  >No</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>

          <b-col md="4" :style="type == 'channelpartner' ? 'display:none' : ''">
            <b-form-group label="Prasentation By Assistant">
              <b-form-radio-group
                id="radio-group-3"
                v-model="presentation"
                name="radio-sub-component"
              >
                <b-form-radio
                  name="some-radios"
                  :disabled="type == 'driver' || farmmanager == true ? true : false"
                  value="Yes"
                  >Yes</b-form-radio
                >
                <b-form-radio
                  name="some-radios"
                  :disabled="type == 'driver' || farmmanager == true ? true : false"
                  value="No"
                  >No</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-card-code title="Special Remarks" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%">
              <table class="table table-bordered">
                <thead class="text">
                  <tr>
                    <th>Sr. No.</th>
                    <th>Special Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in emergencyremarks" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input
                        v-model="item.remarks"
                        :disabled="length > id ? true : false"
                        placeholder="Enter Emargency Remarks"
                      />
                    </td>
                    <td>
                      <b-button
                        v-if="length <= id"
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeRowRemarks(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />
                        <!-- <span>Delete</span> -->
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRowRemarks" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col md="12">
            <b-card-code title="Person Details" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; max-height: 7cm; min-height: 4cm; overflow: auto">
              <table class="table table-bordered" style="min-width: 25cm">
                <thead class="text">
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Mobile No.</th>
                    <th>Age</th>
                    <th style="width: 5cm">Gender</th>
                    <th>City</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in person" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input
                        v-model="item.name"
                        placeholder="Enter Name"
                        :disabled="
                          (source && source.id == userID) ||
                          type == 'admin' ||
                          type == 'sitemanager'
                            ? false
                            : true
                        "
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.mobile"
                        maxLength="10"
                        placeholder="Enter Mobile No"
                        :disabled="
                          (source && source.id == userID) ||
                          type == 'admin' ||
                          type == 'sitemanager'
                            ? false
                            : true
                        "
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.age"
                        placeholder="Enter Age"
                        :disabled="
                          (source && source.id == userID) ||
                          type == 'admin' ||
                          type == 'sitemanager'
                            ? false
                            : true
                        "
                      />
                    </td>
                    <td>
                      <v-select
                        v-model="item.gender"
                        placeholder="None"
                        :options="['Male', 'Female', 'Other']"
                        :disabled="
                          (source && source.id == userID) ||
                          type == 'admin' ||
                          type == 'sitemanager'
                            ? false
                            : true
                        "
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.city"
                        placeholder="Enter City"
                        :disabled="
                          (source && source.id == userID) ||
                          type == 'admin' ||
                          type == 'sitemanager'
                            ? false
                            : true
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <!-- <b-col
            md="4"
            :style="role == 'admin' || type == 'driver' ? '' : 'display:none'"
            class="mt-1"
          >
            <b-form-group>
              <label>Cash in hand (Driver)</label>
              <b-form-input
                v-model="cashinhand"
                :disabled="type == 'driver' ? true : false"
                placeholder="Enter Amount"
                @input="handleAmount"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            :style="role == 'admin' || type == 'driver' ? '' : 'display:none'"
            class="mt-1"
          >
            <b-form-group>
              <label>Remaining Amount</label>
              <b-form-input
                v-model="remainingamount"
                :disabled="type == 'driver' ? true : false"
                placeholder="Enter Amount"
              />
            </b-form-group>
          </b-col> -->
          <!-- <b-col
            md="12"
            :style="type == 'driver' || role == 'admin' ? '' : 'display:none'"
            class="mt-2"
          >
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="min-width: 20cm">
                <thead class="text">
                  <tr>
                    <th>Sr. No</th>
                    <th>Date</th>
                    <th>Remarks</th>
                    <th>Document</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in expense" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <flat-pickr
                        v-model="item.date"
                        class="form-control"
                        :config="{
                          dateFormat: 'd/m/Y',
                          defaultDate: new Date(),
                        }"
                        style="background-color: transparent"
                      />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.remarks"
                        placeholder="Enter Remarks"
                      />
                    </td>
                    <td>
                      <b-form-file
                        v-model="item.document"
                        placeholder="Select Document"
                        drop-placeholder="Drop file here..."
                        @input="
                          handleMultiFileChange($event, 'visitexpense', id)
                        "
                      />
                      <attachment :data="item.document" />
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.amount"
                        placeholder="Enter Amount"
                        type="number"
                        @input="handleAmount"
                      />
                    </td>
                    <td>
                      <b-button
                        variant="outline-danger"
                        class="mt-0"
                        @click="removeRow(id)"
                      >
                        <feather-icon icon="TrashIcon" class="" />

                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRow" class="mb-1 mt-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col> -->
          <b-col md="12" v-if="ifEdit == true && rescheduleLength > 1">
            <b-card-code title="Reschedule Details" no-body />
          </b-col>
          <b-col md="12" v-if="ifEdit == true && rescheduleLength > 1">
            <div style="width: 100%; overflow: auto">
              <table class="table table-bordered" style="min-width: 20cm">
                <thead class="text">
                  <tr>
                    <th>Sr. No</th>
                    <th>Date</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in reschedule" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <flat-pickr
                        v-model="item.date"
                        class="form-control"
                        disabled
                        :config="{
                          dateFormat: 'd/m/Y',
                          defaultDate: new Date(),
                        }"
                        style="background-color: #f8f8f8; cursor: not-allowed"
                      />
                    </td>
                    <td>
                      <flat-pickr
                        v-model="item.time"
                        class="form-control"
                        disabled
                        :config="{
                          dateFormat: 'h:i K',
                          time_24hr: false,
                          noCalendar: true,
                          enableTime: true,
                          defaultDate: new Date(),
                        }"
                        style="background-color: #f8f8f8; cursor: not-allowed"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-col>
          <b-col md="12">
            <b-card-code title="Attachments" no-body />
          </b-col>
          <b-col md="12">
            <div style="width: 100%; overflow: auto; max-height: 500px">
              <table class="table table-bordered" style="min-width: 15cm">
                <thead class="text">
                  <tr>
                    <th></th>
                    <th>Attachment Name</th>
                    <th>Attachment</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, id) in attachment" :key="id">
                    <td>{{ id + 1 }}</td>
                    <td>
                      <b-form-input
                        v-model="item.name"
                        placeholder="Enter Attachment Name"
                      />
                    </td>
                    <td>
                      <b-form-file
                        v-model="item.attachment"
                        placeholder="Select Attachment"
                        drop-placeholder="Drop file here..."
                        @input="
                          handleMultiFileChange($event, 'sitevisit', 'attachment', id)
                        "
                      />
                      <attachment :data="item.attachment" />
                    </td>
                    <td>
                      <b-button variant="outline-danger" @click="removeRowAttachment(id)">
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button variant="primary" @click="addRowAttachment" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <b-col cols="12" class="mt-1">
            <b-button
              variant="primary"
              type="submit"
              @click.prevent="submitForm($event)"
              class="mr-2"
              :disabled="submitDisable"
            >
              Submit
            </b-button>
            <b-button
              v-if="!fromFollowup"
              variant="primary"
              @click="onClickBack"
              class="mr-2"
              >Back
            </b-button>
            <b-button
              v-if="
                (type == 'sitemanager' || role == 'admin' || role == 'channelpartner') &&
                ifEdit == true
              "
              variant="danger"
              @click="cancleVisit()"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-modal
        id="modal-form"
        v-model="popUpVisible"
        no-close-on-backdrop
        size="lg"
        cancel-variant="outline-secondary"
        ok-title="Submit"
        cancel-title="Close"
        title="Add Customer"
        hide-footer
      >
        <add-customer-sitevisit
          :fromSales="true"
          :selectedUser="source"
          :closePopUp="closePopUp"
          :getCustomer="handleClient"
        />
      </b-modal>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import Attachment from "../../../components/Attechment.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BInputGroupAppend,
  BCol,
  BInputGroup,
  BFormFile,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BFormRadio,
  BFormRadioGroup,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axios from "@/components/axios";
import moment from "moment";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import AddCustomerSitevisit from "./AddCustomerSitevisit.vue";

export default {
  components: {
    BAvatar,
    BFormRadio,
    AddCustomerSitevisit,
    Attachment,
    BFormCheckbox,
    PinchScrollZoom,
    flatPickr,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BFormFile,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BFormRadioGroup,
  },
  mixins: [togglePasswordVisibility],
  props: ["fromFollowup", "selectedUserFollowup", "closePopUpFollowup"],
  data() {
    return {
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      sourcetype: "",
      source: "",
      manager: "",
      assistant: "",
      clientname: "",
      drivertype: "",
      driver: "",
      arrivaldate: moment(new Date()).format("DD/MM/YYYY"),
      visitdate: null,
      arrivaltime: moment().format("h:mm:ss"),
      accommodationend: moment(new Date()).format("DD/MM/YYYY"),
      accommodationstart: moment(new Date()).format("DD/MM/YYYY"),
      pickuppoint: "",
      travelby: "",

      vehicleno: "",
      noofperson: 0,
      person: [],
      departurdate: moment(new Date()).format("DD/MM/YYYY"),
      accommodationdate: "",
      droptime: moment().format("h:mm:ss"),
      visittime: null,
      droplocation: "",
      stay: "",
      hotelname: "",
      remarks: {
        vehicleRemarks: "",
        driverRemarks: "",
        remarks: "",
      },

      assigntoOptions: [],
      vehicleOptions: [],
      driverOptions: [],
      assistantOptions: [],
      clientOptions: [],
      sourceOptions: [],
      vehicleValidate: true,
      isstay: "",

      cp: [],
      fr: [],
      team: [],
      users: [],

      ifEdit: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      popUpVisible: false,
      type: "",
      userID: "",
      role: "",
      allCp: [],
      allFr: [],

      vehiclereq: "",
      withcp: "Yes",
      project: "",
      presentation: "Yes",
      cashinhand: 0,
      remainingamount: 0,
      guestmanager: "",
      expense: [],
      check: false,
      guestmanagerOption: [],
      emergencyremarks: [],
      user: "",
      length: 0,
      farmmanager: false,
      allTeam: [],
      farmOptions: [],
      houseOptions: [],
      bunglowOptions: [],
      action: 1,
      rescheduleLength: 0,
      lock: 1,

      reschedule: [],
      date: "",
      time: "",
      display: false,
      resheduleRemarks: false,
      submitDisable: false,
      managerCount: [],
      active: 0,
      is_reschedule: false,
      attachment: [
        {
          name: "",
          attachment: "",
        },
      ],
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    this.user = this.$store.state.app.user_data;
    this.role = this.user.role;
    this.type = this.user.type;
    this.userID = this.user.id;
    if (this.role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Site Visit") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/sitevisit");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/sitevisit");
          }
        }
      });
    }
    if (this.role == "channelpartner") {
      this.sourcetype = "Channel Partner";
      this.handleClient(this.user);
      this.getChannelpartner();
    } else if (this.role == "franchise") {
      this.sourcetype = "Franchise";
      this.handleClient(this.user);
      this.getFranchise();
    } else if (this.role == "employee" && this.type !== "sitemanager") {
      this.sourcetype = "Employee";
      this.handleClient(this.user);
      this.source = this.user;
    }
    if (
      this.type == "farmhousemanager" ||
      this.type == "guesthousemanager" ||
      this.type == "bungalowmanager"
    ) {
      this.farmmanager = true;
    }
    if (this.fromFollowup == true) {
      this.getUsers(this.selectedUserFollowup.assignto);
      this.clientname = this.selectedUserFollowup;
    }

    this.ifEdit = !!this.$route.params.id;
    if (this.ifEdit) {
      this.handleVehicle();
    }
    await this.getManager();
    this.getCountOfVisit();

    this.handleVehicle();
    this.getDriver();
    this.ifEdit && this.getEditValue();
  },

  methods: {
    handleIsStay() {
      if (!this.isstay || !this.isstay.id) {
        this.guestmanager = {};
        this.stay = null;
      }
    },
    addRowAttachment() {
      this.attachment.push({
        name: "",
        attachment: "",
      });
    },
    removeRowAttachment(index) {
      this.attachment.splice(index, 1);
    },
    handleClientPerson(e) {
      if (!e) {
        this.person = [];
        return;
      }
      if (this.person.length == 0) {
        this.person.push({
          name: e.name,
          mobile: e.mobile,
          gender: e.gender,
          city: e.city,
        });
      } else {
        this.person[0] = {
          name: e.name,
          mobile: e.mobile,
          gender: e.gender,
          city: e.city,
        };
        this.$forceUpdate();
      }
    },
    getprofileImage(image) {
      if (image !== undefined && image !== null && image !== "") {
        let test = image.includes(this.imagePath) ? image : this.imagePath + image;
        return test;
      }
      return image;
    },
    handleCount() {
      // this.managerCount.map((item) => {
      //   if (item.manager == this.manager.id && item.total >= 5) {
      //     this.$swal({
      //       title: "Warning",
      //       timer: 5000,
      //       text: `${this.manager.name} has already ${item.total} visit`,
      //       icon: "warning",
      //       customClass: {
      //         confirmButton: "btn btn-primary",
      //       },
      //       buttonsStyling: false,
      //     });
      //   }
      // });
    },
    async getCountOfVisit() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getmanagercount`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.managerCount = response.data;
      });
    },
    handleReschedule() {
      if (this.ifEdit) {
        if (this.date !== this.visitdate) {
          this.reschedule.push({
            date: moment(this.visitdate).format("DD/MM/YYYY"),
            time: this.visittime,
          });
          this.is_reschedule = true;
        }
        // if (
        //   this.date !== this.visitdate
        // ) {
        //   this.reschedule[this.rescheduleLength].date = this.visitdate;
        //   this.reschedule[this.rescheduleLength].time = this.visittime;

        // }
      }
    },
    cancleVisit() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.action = 0;
          this.submitForm();
        }
      });
    },
    async handleFarmManager() {
      this.guestmanager = {};
      this.guestmanagerOption = [];
      if (this.stay == "Farm House") {
        this.guestmanagerOption = this.farmOptions;
      } else if (this.stay == "Banglow") {
        this.guestmanagerOption = this.bunglowOptions;
      } else if (this.stay == "Greentech") {
        this.guestmanagerOption = this.houseOptions;
      }
    },
    handleremarks(e) {
      this.emergencyremarks.map((item, id) => {
        if (id >= this.length && !item.remarks.includes("Enter By")) {
          let type = this.user.type;
          let name = this.user.name;
          let remarks = `Enter By ${type} (${name})`;
          this.emergencyremarks[id].remarks = item.remarks + " " + remarks;
        }
      });
    },
    handleAmount() {
      this.remainingamount = "";
      let amount = 0;
      if (this.expense.length > 0) {
        this.expense.map((item) => {
          amount += parseFloat(item.amount);
        });
      }
      amount = isNaN(amount) ? 0 : amount;
      this.remainingamount = this.cashinhand - amount;
    },
    async handleMultiFileChange(e, type, name, index) {
      this.submitDisable = true;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: formData,
        url: `${this.baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          if (name == "attachment") {
            this.attachment[index].attachment =
              process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          }
          this.expense[index].document =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => {
          console.log(error, "error");
        })
        .finally(() => {
          this.submitDisable = false;
        });
    },
    async getUsers(id) {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getusersbyid/${id}`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.source = response.data.data[0];
        if (this.source.role == "channelpartner") {
          this.sourcetype = "Channel Partner";
        } else if (this.source.role == "franchise") {
          this.sourcetype = "Franchise";
        } else if (this.source.role == "employee") {
          this.sourcetype = "Employee";
        } else if (this.source.role == "admin") {
          this.sourcetype = "Company";
        }
      });
    },
    closePopUp() {
      this.popUpVisible = false;
    },
    handlePopUp() {
      if (this.source || this.sourcetype == "Company") {
        this.popUpVisible = true;
      } else {
        this.$swal({
          title: "Warning",
          timer: 5000,
          text: "Plese Select Source !",
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    async getManager() {
      this.assigntoOptions = [];
      this.assistantOptions = [];
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getEmployee`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.allTeam = response.data.data;
        response.data.data.map((item) => {
          if (item.type == "sitemanager") {
            this.assigntoOptions.push(item);
          } else if (item.type == "siteassistant") {
            this.assistantOptions.push(item);
          } else if (item.type == "farmhousemanager") {
            this.farmOptions.push(item);
          } else if (item.position && item.position.name == "Greentech Manager") {
            this.houseOptions.push(item);
          } else if (item.type == "bungalowmanager") {
            this.bunglowOptions.push(item);
          }
        });

        if (this.role == "admin" || this.type == "sitemanager") {
          if (this.assigntoOptions.length == 1) {
            this.manager = this.assigntoOptions[0];
          }
        }
      });
    },
    async getAllUser() {
      const requestOptionsUsers = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getUsers`,
      };

      await axios(requestOptionsUsers).then((response) => {
        this.users = response.data.data;
      });
    },
    async handleClient(e) {
      let id;
      if (e && e.id) {
        id = e.id;
      } else {
        id = e;
      }
      const data = {
        assignto: id,
      };
      await axios({
        method: "POST",
        url: `${this.baseApi}/getcustomerassignby`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
      })
        .then((json) => {
          this.clientOptions = json.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleSource(e, id) {
      this.sourceOptions = [];
      if (e == "Channel Partner") {
        this.getChannelpartner(id);
      } else if (e == "Franchise") {
        this.getFranchise(id);
      } else if (e == "Employee") {
        this.getTeam(id);
      } else if (e == "Company") {
        await this.getAdmin();
        this.handleClient(this.source);
      }
    },
    async getAdmin() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getalladmins`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.source = json.data[0];
        })
        .catch((error) => console.log(error, "error"));
    },
    // handleProject() {
    //   this.projectOptions.map((item) => {
    //     if (item.id == this.project) {
    //       this.project = item;
    //     }
    //   });
    // },
    handlePerson(e) {
      this.person = [];
      for (let i = 0; i < this.noofperson; i++) {
        this.person.push({
          name: "",
          mobile: "",
          age: "",
          gender: "",
          City: "",
        });
      }
    },

    async getProject() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/project`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.projectOptions = json.data.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    // validateVehicalClick() {
    //   const regex = new RegExp(
    //     /^[A-Z]{2}[\s]{1}[0-9]{2}[\s]{1}[A-Z]{1,2}[\s]{1}[0-9]{4}$/
    //   );
    //   this.vehicleno = this.vehicleno.toUpperCase();
    //   this.vehicleno.length == 2
    //     ? (this.vehicleno = this.vehicleno.concat(" "))
    //     : this.vehicleno;
    //   this.vehicleno.length == 5
    //     ? (this.vehicleno = this.vehicleno.concat(" "))
    //     : this.vehicleno;
    //   this.vehicleno.length == 8
    //     ? (this.vehicleno = this.vehicleno.concat(" "))
    //     : this.vehicleno;
    //   if (regex.test(this.vehicleno) == true) {
    //     this.vehicleValidate = true;
    //   } else {
    //     this.vehicleValidate = false;
    //   }
    // },
    addRowRemarks() {
      this.emergencyremarks.push({
        remarks: "",
      });
    },
    removeRowRemarks(id) {
      this.emergencyremarks.splice(id, 1);
    },
    addRow() {
      this.expense.push({
        date: moment(new Date()).format("DD/MM/YYYY"),
        time: moment().format("h:mm:ss"),
        remarks: "",
      });
    },
    removeRow(id) {
      this.expense.splice(id, 1);
    },
    // handleDriver(e, id, type) {
    //   this.driverOptions = [];
    //   if (e == "Company") {
    //     this.getDriver(id);
    //     if (!this.ifEdit) {
    //       this.handleVehicle();
    //     }
    //     this.vehicleValidate = true;
    //   } else if (e == "Channel Partner") {
    //     this.getChannelpartner(id, type);
    //   }
    // },
    async handleVehicle() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getvehicle`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.vehicleOptions = json.data.data;
          this.vehicleOptions.push({ vehicleno: "Other", id: 0 });
        })
        .catch((error) => console.log(error, "error"));
    },
    async getDriver(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getdriver`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.driverOptions = json.data.data;
          this.driverOptions.push({ id: 0, name: "Other" });
          if (id !== undefined) {
            this.driverOptions.map((item) => {
              if (item.id == id) {
                this;
                this.driver = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    // async manager() {
    //   await axios({
    //     method: "GET",
    //     url: `${this.baseApi}/getsitevisitmanager`,
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${this.accessToken}`,
    //     },
    //   })
    //     .then((json) => {
    //       this.team = json.data.data;
    //     })
    //     .catch((error) => console.log(error, "error"));
    // },
    async getChannelpartner(id, type) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getallcp`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.cp = [];
          this.allCp = json.data.data;
          if (this.role != "channelpartner") {
            this.sourceOptions = this.allCp;
          } else {
            this.allCp.map((item) => {
              if (item.id == this.userID) {
                this.cp.push(item);
              }
            });
            this.handleCP(this.userID);
            this.sourceOptions = this.cp;
          }

          // if (type == "driver") {
          //   this.driverOptions = json.data.data;
          //   if (id !== undefined) {
          //     // let data = this.driverOptions.filter((item) => item.id == id);
          //     // this.driver = data[0];

          //     this.driverOptions.map((item) => {
          //       if (item.id == id) {
          //         this.driver = item;
          //       }
          //     });
          //   }
          // } else {
          if (id !== undefined) {
            this.sourceOptions.map((item) => {
              if (item.id == id) {
                this.source = item;
              }
            });
            // let data = this.sourceOptions.filter((item) => item.id == id);
            // this.source = data[0];
          }
          // }
        })
        .catch((error) => console.log(error, "error"));
    },
    handleCP(id) {
      this.allCp.map((item) => {
        if (item.parent == id) {
          this.cp.push(item);
          this.handleCP(item.id);
        }
      });
    },
    async getTeam(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getteam`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.sourceOptions = json.data.data;
          if (id !== undefined) {
            this.sourceOptions.map((item) => {
              if (item.id == id) {
                this.source = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    async getFranchise(id) {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getallfranchise`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.fr = [];
          this.allFr = json.data.data;
          if (this.role != "franchise") {
            this.sourceOptions = this.allFr;
          } else {
            this.allFr.map((item) => {
              if (item.id == this.userID) {
                this.fr.push(item);
              }
            });
            this.handleFr(this.userID);
            this.sourceOptions = this.fr;
            console.log(this.fr);
          }

          if (id !== undefined) {
            this.sourceOptions.map((item) => {
              if (item.id == id) {
                this.source = item;
              }
            });
          }
        })
        .catch((error) => console.log(error, "error"));
    },
    handleFr(id) {
      this.allFr.map((item) => {
        if (item.parent == id) {
          this.fr.push(item);
          this.handleFr(item.id);
        }
      });
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getsitevisitById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.attachment = item.attachment ? JSON.parse(item.attachment) : [];
        this.sourcetype = item.sourcetype;
        this.reschedule = item.reschedule ? item.reschedule : [];
        this.rescheduleLength = item.reschedule ? item.reschedule.length : 0;
        this.date = item.visitdate;
        this.time = item.visittime;
        this.source = item.source;
        this.manager = item.manager;
        this.assistant = item.assistant;
        this.clientname = item.clientname;
        this.handleClient(item.source);
        this.drivertype = item.drivertype;
        this.driver = item.driver;
        this.arrivaldate =
          item.arrivaldate == "00/00/0000" || item.arrivaldate == null
            ? null
            : item.arrivaldate;
        this.visitdate =
          item.visitdate == "00/00/0000" || item.visitdate == null
            ? null
            : item.visitdate;

        this.arrivaltime = item.arrivaltime;
        this.visittime = item.visittime;
        this.pickuppoint = item.pickuppoint;
        this.travelby = item.travelby;
        this.vehicleno =
          item.vehicleno == 0
            ? { vehicleno: "Other", id: 0 }
            : item.vehicleno
            ? { vehicleno: item.vehicleno, id: item.vehicleno }
            : "";
        this.noofperson = item.noofperson;
        this.person = item.person;
        this.departurdate =
          item.departurdate == "00/00/0000" || item.departurdate == null
            ? null
            : item.departurdate;
        this.droptime = item.droptime;
        this.droplocation = item.droplocation;
        this.hotelname = item.hotelname;
        this.remarks =
          item.remarks == null
            ? { vehicleRemarks: "", driverRemarks: "", remarks: "" }
            : item.remarks;
        this.isstay = item.isstay;
        this.vehiclereq = item.vehiclereq;
        this.withcp = item.withcp;
        this.project = item.project;
        this.presentation = item.presentation;
        this.cashinhand = item.cashinhand;
        this.remainingamount = item.remainingamount;
        this.action = item.action;
        this.active = item.active;
        this.emergencyremarks = item.emergencyremarks ? item.emergencyremarks : [];
        this.length = this.emergencyremarks ? this.emergencyremarks.length : 0;
        this.expense = item.expense ? item.expense : [];
        if (item.isstay == 1) {
          this.accommodationstart =
            item.accommodationstart == "00/00/0000" || item.accommodationstart == null
              ? null
              : item.accommodationstart;

          this.accommodationend =
            item.accommodationend == "00/00/0000" || item.accommodationend == null
              ? null
              : item.accommodationend;
          this.stay = item.stay;
        } else {
          this.accommodationstart = null;
          this.accommodationend = null;
          this.stay = null;
        }
        this.handelStay();
        this.handleremarks("edit");
        this.handleFarmManager(this.stay);
        this.guestmanager = item.guestmanager;
        this.handleIsStay();

        if (!this.manager && (this.role == "admin" || this.type == "sitemanager")) {
          if (this.assigntoOptions.length == 1) {
            this.manager = this.assigntoOptions[0];
          }
        }
      });
    },
    handelStay() {
      if (this.isstay) {
        this.isstay = {
          id: 1,
          name: "Yes",
        };
      } else {
        this.isstay = {
          id: 0,
          name: "No",
        };
      }
    },

    onClickBack() {
      this.$router.push("/crm/sitevisit");
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        if ((success == false || this.vehicleValidate == false) && this.action == 1) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if ((success && this.vehicleValidate == true) || this.action == 0) {
          this.is_reschedule = false;
          if (this.ifEdit == true) {
            this.handleReschedule();
          } else {
            this.reschedule = [
              {
                date: this.visitdate,
                time: this.visittime,
              },
            ];
          }
          this.handleremarks();

          const data = {
            sourcetype: this.sourcetype,
            source: this.source ? this.source.id : "",
            manager: this.manager ? this.manager.id : "",
            assistant: this.assistant ? this.assistant.id : "",
            guestmanager: this.guestmanager ? this.guestmanager.id : "",
            drivertype: this.drivertype,
            driver: this.driver ? this.driver.id : this.driver,
            arrivaldate: this.arrivaldate,
            visitdate: this.visitdate,
            arrivaltime: this.arrivaltime,
            pickuppoint: this.pickuppoint,
            travelby: this.travelby,
            vehicleno: this.vehicleno.vehicleno,
            clientname: this.clientname.id,
            noofperson: this.noofperson,
            stay: this.isstay.id == 1 ? this.stay : null,
            hotelname: this.hotelname,
            departurdate: this.departurdate,
            accommodationstart: this.isstay.id == 1 ? this.accommodationstart : null,
            accommodationend: this.isstay.id == 1 ? this.accommodationend : null,
            visittime: this.visittime,
            droptime: this.droptime,
            droplocation: this.droplocation,
            person: this.person,
            remarks: this.remarks,
            isstay: this.isstay ? this.isstay.id : "",
            vehiclereq: this.vehiclereq,
            withcp: this.withcp,
            project: this.project,
            presentation: this.presentation,
            cashinhand: this.cashinhand,
            remainingamount: this.remainingamount,
            expense: this.expense,
            attachment: this.attachment ? JSON.stringify(this.attachment) : "",
            emergencyremarks: this.emergencyremarks ? this.emergencyremarks : [],
            active: this.active,
            is_reschedule: this.is_reschedule,
            reschedule: this.reschedule ? this.reschedule : [],
            action: this.action,
          };
          if (
            (!this.manager && this.type == "channelpartner") ||
            (this.source &&
              this.source.id == this.userID &&
              this.type !== "admin" &&
              this.type !== "sitemanager")
          ) {
            data.lock = 1;
          } else {
            data.lock = 0;
          }
          // var ifEdit = this.$route.params.id ? true : false;

          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          this.submitDisable = true;

          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/sitevisit/${this.$route.params.id}`
              : `${baseApi}/sitevisit`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((response) => {
              this.$swal({
                title: "Submited",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              if (this.fromFollowup == true) {
                this.closePopUpFollowup();
              } else {
                this.$router.push("/crm/sitevisit");
              }
            })
            .catch((error) => {
              let vehicle, message;
              const code = error.toString().includes("409");
              if (code) {
                vehicle = error.response.data.message.original.vehicle
                  ? error.response.data.message.original.vehicle[0]
                  : "";

                message = vehicle;
              }

              this.$swal({
                title: "Error!",
                text: code ? message : error.response.data.message,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.submitDisable = false;
            });
        }
      });
    },
  },
};
</script>
